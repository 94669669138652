import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import SEO from "../components/marketing/seo"
import Header from "../components/marketing/Header/Header.jsx"
import Footer from "../components/marketing/Footer/Footer.jsx"
import "../scss/pages/Blog.scss"
import BlogPostListItem from "../components/marketing/BlogPostListItem/BlogPostListItem";

export const pageQuery = graphql`
  query {
    blogHero: file(relativePath: { eq: "blog-hero.png" }) {
      childImageSharp{
        fluid(maxWidth: 585, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`

class Blog extends React.Component {
  render() {
    
    const blogPosts = this.props.data.allMarkdownRemark.edges.map(post => {
      return <BlogPostListItem post={post} key={post.node.fields.slug} />
    })

    let blogHero = this.props.data.blogHero.childImageSharp.fluid

    return (
      <React.Fragment>

        <SEO title={`Blog - Hammock`}
             description={`Thoughts and ideas to help you run your freelance business.`}
             keywords={['freelance', 'freelancers', 'freelance business', 'runway', 'freelance runway', 'freelance forecasting', 'expense tracking', 'expenses', 'freelance taxes', 'freelance quarterly estimated taxes', 'freelance tax', 'blog', 'freelancing tools', 'freelance tools' ]}
        />

        <Header />

        <div className="blog-hero">
          <div className="container">
            <div className="row">
              <div className="col-md-2 offset-md-5">
                <Img className="hero-illustration" fluid={blogHero} />
              </div>
              <div className="col-6 offset-3">
                <h1 className="section-title">The Hammock Blog</h1>
                <h2>Hammock Happenings, and Thoughts for Freelancers</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-content">
          <div className="container">
            <div className="row">
              {blogPosts}
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    )
  }
}

export default Blog
