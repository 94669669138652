import React from "react"
import { Link } from "gatsby"

import "./BlogPostListItem.scss"

class BlogPostListItem extends React.Component {
  render() {
    let post = this.props.post.node

    return (
      <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        
        <Link to={`/blog${post.fields.slug}`} className="blog-post-card-link">
          <div className="blog-post-card">
            <h5 className="section-title">
              <time className="published-date">
                {post.frontmatter.date}
              </time>
            </h5>

            <h4 className="title">{post.frontmatter.title}</h4>

            <p className="snippet">{post.frontmatter.description}</p>

            <span className="read-more">
              Read More
            </span>
          </div>
        </Link>
      </div>
    )
  }
}

export default BlogPostListItem


